import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';

import * as mso from '@mod-mso/webdesigns/mso/mso';

import '@mod-mso_templates/webdesigns/penitus/penitus';

import './dehogewal.scss';

dompack.onDomReady(() => {
  new mso.MSOSite();
  const { ishome, openingtimes_html } = whintegration.config.site;

  if (ishome) {
    console.info('Inserting openingtimes');
    const slideshowText = dompack.qS('.swiper-slide-active .w-slideshow__textcontainer');

    slideshowText.insertAdjacentHTML('beforeend', openingtimes_html);
  }
});
